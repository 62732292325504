import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-flexbox-grid';

import PreMixedStep1 from 'images/pre-mixed-step-1.png';
import PreMixedStep2 from 'images/pre-mixed-step-2.png';
import PreMixedStep3 from 'images/pre-mixed-step-3.png';
import StandardStep1Img from 'images/standard-step-1.png';
import StandardStep2Img from 'images/standard-step-2.png';
import StandardStep3Img from 'images/standard-step-3.png';
import StandardStep4Img from 'images/standard-step-4.png';
import StandardStep5Img from 'images/standard-step-5.png';

// import page style
import './workflow-scenarios.scss';

const ResouresPage = () => {
  return (
    <Layout indication="patient" className="workflow-scenarios">
      <Seo pageTitle="Workflow Scenarios Page" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <h1>Simplifying Your Pharmacy Workflow Preparation</h1>
          <Row>
            <Col xs={12} md={12}>
              <div className="container-parent-vial-steps">
                <div className="container-vial-steps premixed-vials">
                  <div className="text-steps">
                    <p className="title-steps bold">
                      THREE Steps Using RTU Vial
                    </p>
                    <ol>
                      <li>Draw medication from vial into syringe</li>
                      <li>Remove needle</li>
                      <li>
                        Administer through IV line by connecting to Luer Lock
                      </li>
                    </ol>
                  </div>
                  <div className="container-premixed-vials-icons">
                    <img src={PreMixedStep1} alt="step 1 using RTU vial" />
                    <img src={PreMixedStep2} alt="step 2 using RTU vial" />
                    <img src={PreMixedStep3} alt="step 3 using RTU vial" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="container-versus-block gutter-all">
                <div className="versus-block">
                  <p className="bold">VS</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <div className="container-parent-vial-steps">
                <div className="container-vial-steps standard-vials">
                  <div className="text-steps">
                    <p className="title-steps bold">
                      FIVE Steps Using Standard Vial
                    </p>
                    <ol>
                      <li>Draw medication from vial into syringe</li>
                      <li>Draw diluent from IV bag into syringe</li>
                      <li>Swirl syringe to mix</li>
                      <li>Remove needle</li>
                      <li>
                        Administer through IV line by connecting to Luer Lock
                      </li>
                    </ol>
                  </div>
                  <div className="container-premixed-vials-icons">
                    <img
                      src={StandardStep1Img}
                      alt="step 1 using standard vial"
                    />
                    <img
                      src={StandardStep2Img}
                      alt="step 2 using standard vial"
                    />
                    <img
                      src={StandardStep3Img}
                      alt="step 3 using standard vial"
                    />
                  </div>
                  <div className="container-premixed-vials-icons">
                    <img
                      src={StandardStep4Img}
                      alt="step 4 using standard vial"
                    />
                    <img
                      src={StandardStep5Img}
                      alt="step 5 using standard vial"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="container-download-now-block">
                <div className="download-now-block">
                  <div className="download-now-subblock" />
                  <p className="text-for-more-info bold">
                    For more information, download our Ephedrine RTU vial
                    one-pager
                  </p>
                  <button>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/3560f4fa-2159-45df-8203-58285d13fd12/3560f4fa-2159-45df-8203-58285d13fd12_source__v.pdf"
                    >
                      Download Now
                    </a>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ResouresPage;
